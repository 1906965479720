import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { MdPlayCircleFilled } from 'react-icons/md';
import { FaChevronRight } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { CatalogApp, CatalogWidget } from '@/webapi/use-widget-catalog-api';
import { DefaultTypography } from '@/components/typography';
import { CardButton, MixedCardLayout } from '@/components/card-button';
import { AppsVideoExamplesContent } from '@/features/editor/widgets/shared/apps-catalog/video-examples-content';
import { VideoPlayerOverlay } from '@/components/video-player-overlay';
import { CheckoutWidgetManifest } from '@/webapi/checkout-widget-props';

export function CatalogAppTile({
  app,
  onClick,
  onMouseEnter,
  onMouseLeave,
  longTiles,
  isDesk,
  isDisabled,
  disabledText,
}: {
  app: CatalogApp | CatalogWidget | CheckoutWidgetManifest;
  onClick?: (
    app: CatalogApp | CatalogWidget | CheckoutWidgetManifest,
    setLoading: (b: boolean) => void,
  ) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  longTiles?: boolean;
  isDesk?: boolean;
  isDisabled?: boolean;
  disabledText?: string;
}) {
  const [loading, setLoading] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const videoExample = useMemo(
    () => AppsVideoExamplesContent?.[app?.id],
    [app],
  );

  const onExampleClicked = (ev) => {
    ev.stopPropagation();
    setIsPopoverOpen(true);
  };

  const tooltipId = useMemo(() => `tooltip-${app?.id}`, [app]);

  return (
    <>
      {isDisabled && (
        <ReactTooltip
          id={tooltipId}
          place="top"
          backgroundColor="#fff"
          textColor="#000"
          className="disabled-tooltip"
        >
          <span>{disabledText}</span>
        </ReactTooltip>
      )}
      <TouchWrapper
        data-tip
        data-for={tooltipId}
        className={app.id}
        onClick={() =>
          !isPopoverOpen && !isDisabled && !loading && onClick(app, setLoading)
        }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        longTiles={longTiles}
        isDisabled={isDisabled}
      >
        <CardButton padding="0" canBeSelected>
          <MixedCardLayout
            alignItems="stretch"
            horizontalDistribution="1fr 2fr"
            horizontalGap="2rem"
            verticalGap={longTiles ? `1.2rem` : `2rem`}
            padding="0"
            content={<Image src={app.image} alt="" />}
          >
            <Title longTiles={longTiles}>{app.name}</Title>
            <Description isDesk={isDesk} longTiles={longTiles}>
              {app?.hint}
            </Description>
            {longTiles && (
              <>
                {videoExample && (
                  <VideoExampleWrapper>
                    <MdPlayCircleFilled />
                    <span onClick={onExampleClicked}>See Example</span>
                  </VideoExampleWrapper>
                )}
                <RightChevron>
                  <FaChevronRight />
                </RightChevron>
                {isPopoverOpen && (
                  <VideoPlayerOverlay
                    url={videoExample}
                    onBackdropClicked={() => setIsPopoverOpen(false)}
                  />
                )}
              </>
            )}
          </MixedCardLayout>
        </CardButton>
      </TouchWrapper>
    </>
  );
}

const TouchWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${(p) =>
    p.longTiles
      ? ``
      : `
  & > div {
    min-height: 14rem;
  }
  `}

  opacity: ${(p) => (p.isDisabled ? 0.8 : 1)};
  filter: grayscale(${(p) => (p.isDisabled ? 1 : 0)});
  order: ${(p) => (p.isDisabled ? 100 : `unset`)};
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;

  border-top-left-radius: 1.8rem;
  border-bottom-left-radius: 1.8rem;

  margin: 0;
  padding: 0;
`;

const Title = styled(DefaultTypography)`
  && {
    color: #272d33 !important;
    font-family: 'Inter, serif !important';
    font-size: 1.6rem !important;
    font-weight: 600 !important;
    letter-spacing: -0.48px !important;
    text-transform: none !important;
    padding-right: 1rem;
    line-height: 2rem !important;
  }
`;

const Description = styled(DefaultTypography)`
  && {
    color: #5b656e !important;
    font-family: 'Inter, serif !important';
    padding-right: ${(p) =>
      // eslint-disable-next-line no-nested-ternary
      p.longTiles
        ? p?.isDesk
          ? `5rem !important`
          : `10rem !important`
        : `unset`};
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    letter-spacing: -0.32px !important;
    text-overflow: ellipsis !important;
    padding-right: 1rem;
    line-height: 1.5rem !important;
  }
`;

const VideoExampleWrapper = styled.div`
  font-family: Inter, serif !important;
  display: grid;
  grid-template-columns: auto 1fr;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-gap: 0.8rem;
  font-size: 1.4rem !important;

  span {
    text-decoration: underline;
    font-size: 1rem !important;
    transition: color 0.2s linear;
  }

  span:hover {
    color: black;
  }
`;

const RightChevron = styled.div`
  position: absolute;
  right: 2rem;
  top: 0;
  bottom: 0;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem !important;
`;
